import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { getLocalDate } from '../../../config/utils/dateHelpers';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewExtraInfo = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getShipToValue = useCallback(() => {
    return `${doc?.shipTo} ${doc?.shipToCity || ''} ${doc?.shipToPostalCode || ''}`;
  }, []);

  return (
    <div className="flex-center-start-column-nowrap">
      <div className="flex-center-center-row-wrap">
        <div className="boldText1">{t('PDF.DOC_DATA')}</div>
      </div>
      <div className="flex-center-start-column-nowrap simpleBorder">
        <div className="containerExtraInfo">
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.DOC_PURPOSE')}:</div>
            <div className="normalText1">{t(`DOC_PURPOSES.${doc?.docPurpose?.name}`)}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.PAYMENT_TYPE')}:</div>
            <div className="normalText1">{t(`PAYMENT_TYPES.${doc?.paymentType?.name}`)}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.DELIVERY_TYPE')}:</div>
            <div className="normalText1">{doc?.shipping}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.DELIVERY_TIME')}:</div>
            <div className="normalText1">{doc?.shipDate ? getLocalDate(doc?.shipDate) : ' - '}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.DELIVERY_PLACE')}:</div>
            <div className="normalText1">{doc?.shipFrom}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 text-no-wrap padding-r-6">{t('PDF.DELIVERY_RECEIVE')}:</div>
            <div className="normalText0 truncate-2lines">{getShipToValue()}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.VAT_REGIME')}:</div>
            <div className="normalText1">{t(`VAT_REGIME.${doc?.vatRegime?.name}`)}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6 text-no-wrap">{t('PDF.VAT_EXCEPTION_CAT')}:</div>
            <div className="normalText1">
              {doc?.vatExceptionCategoryId ? t(`VAT_EXCEPTION_CAT.${doc?.vatExceptionCategory?.name}`) : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
