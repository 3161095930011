import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { useTranslation } from 'react-i18next';
import { getLocalDate } from '../../../config/utils/dateHelpers';

interface Props {
  doc: InvoiceInterface;
}

export const PdfExtraInfo = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getShipToValue = useCallback(() => {
    return `${doc?.shipTo} ${doc?.shipToCity || ''} ${doc?.shipToPostalCode || ''}`;
  }, []);

  return (
    <View style={styles.columnCenter}>
      <View style={styles.center}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.DOC_DATA')}</Text>
      </View>
      <View style={[styles.container, { width: 275 }]}>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.DOC_PURPOSE')}:</Text>
          <Text style={pdfStyles.smallTitleRegular}>{t(`DOC_PURPOSES.${doc?.docPurpose?.name}`)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.PAYMENT_TYPE')}:</Text>
          <Text style={pdfStyles.smallTitleRegular}>{t(`PAYMENT_TYPES.${doc?.paymentType?.name}`)}</Text>
        </View>
        <View style={[styles.row]}>
          <View style={styles.row}>
            <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.DELIVERY_TYPE')}:</Text>
            <Text style={pdfStyles.smallTitleRegular}>{doc?.shipping}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.DELIVERY_TIME')}:</Text>
          <Text style={pdfStyles.smallTitleRegular}>{doc?.shipDate ? getLocalDate(doc?.shipDate) : ' - '}</Text>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.DELIVERY_PLACE')}:</Text>
          <Text style={pdfStyles.smallTitleRegular}>{doc?.shipFrom}</Text>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, styles.placeholder, { paddingRight: 2 }]}>
            {t('PDF.DELIVERY_RECEIVE')}:
          </Text>
          <Text style={[pdfStyles.fontNormal7, pdfStyles.maxWidth180, pdfStyles.maxLines2]}>{getShipToValue()}</Text>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.VAT_REGIME')}:</Text>
          <Text style={pdfStyles.smallTitleRegular}>{t(`VAT_REGIME.${doc?.vatRegime?.name}`)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.VAT_EXCEPTION_CAT')}:</Text>
          <Text style={[pdfStyles.smallTitleRegular, pdfStyles.maxWidth200, pdfStyles.maxLines2]}>
            {doc?.vatExceptionCategoryId ? t(`VAT_EXCEPTION_CAT.${doc?.vatExceptionCategory?.name}`) : ''}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  start: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  container: {
    borderWidth: 1,
    borderRadius: 6,
    flexDirection: 'column',
    padding: 2,
  },
  placeholder: {
    width: 84,
  },
});
