import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flexDirection: 'column',
  },
  flexStartStartRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  flexStartCenterRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flexStartEndRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  flexStartSpaceRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 0,
  },
  regularTitle: {
    fontSize: 10,
    lineHeight: 0,
  },
  smallTitle: {
    fontSize: 8,
    fontWeight: 'bold',
    lineHeight: 0,
  },
  smallTitleRegular: {
    fontSize: 8,
    lineHeight: 0,
  },
  smallestTitle: {
    fontSize: 6, // TODO: 7
    fontWeight: 'bold',
  },
  smallestTitleRegular: {
    fontSize: 6,
    fontWeight: 'normal',
  },
  fontNormal7: {
    fontSize: 7,
    fontWeight: 'normal',
  },
  body: {
    fontSize: 10,
    lineHeight: 0,
  },
  body1: {
    fontSize: 9,
    lineHeight: 0,
  },
  body2: {
    fontSize: 8,
    lineHeight: 0,
  },
  width80: {
    width: 80,
    textAlign: 'center',
  },
  width60start: {
    width: 60,
  },
  width60: {
    width: 60,
    textAlign: 'center',
  },
  width40: {
    width: 40,
    textAlign: 'center',
  },
  width30: {
    width: 30,
    textAlign: 'center',
  },
  maxWidth160: {
    maxWidth: 160,
  },
  maxWidth180: {
    maxWidth: 180,
  },
  maxWidth200: {
    maxWidth: 200,
  },
  simpleBorder: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 6,
    padding: 2,
  },
  maxLines1: {
    maxLines: 1,
    textOverflow: 'ellipsis',
  },
  maxLines2: {
    maxLines: 2,
    textOverflow: 'ellipsis',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  ionButton: {
    height: 36,
    backgroundColor: 'var(--ion-color-primary)',
    textDecoration: 'none',
    color: 'white',
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 16,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: 2,
    marginRight: 2,
  },
  ionButtonOutline: {
    height: 36,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    borderColor: 'var(--ion-color-primary)',
    borderWidth: 1,
    borderStyle: 'solid',
    color: 'var(--ion-color-primary)',
    borderRadius: 4,
    paddingLeft: 40,
    paddingRight: 40,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});
